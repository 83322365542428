import { ActionType, IAppAction } from '../../actions/Helpers';


const initialState = {
    notificationsList: [] as any[],
    next: null,
    previous: null,
    notificationDetails: {} as any
}
export const notificationsReducer = (state = initialState, action = {} as IAppAction) => {
    switch (action.type) {
        case ActionType.SET_NOTIFICATIONS_LIST: {
            let updateGridArray: any[] = [];
            if (action.payload.results) {
                if (state.notificationsList.length === 0) {
                    updateGridArray.push(...action.payload.results)
                }
                else if (action.payload.results.length !== 0) {
                    updateGridArray = [...state.notificationsList]
                }
            }
            return {
                ...state,
                notificationsList: updateGridArray,
                next: action.payload.next,
                previous: action.payload.previous
            };
        }
        case ActionType.NEXT_OR_PREVIOUS_NOTIFICATIONS_LIST: {
            let updateGridArray: any[] = [];
            if (action.payload.results) {
                if (state.notificationsList.length === 0) {
                    updateGridArray.push(...action.payload.results)
                }
                else if (action.payload.results.length !== 0) {
                    updateGridArray = [...state.notificationsList]
                }
            }
            return {
                ...state,
                notificationsList: updateGridArray,
                next: action.payload.next,
                previous: action.payload.previous
            };
        }

        case ActionType.GET_NOTIFICATION_BY_ID_SUCCESS: {
            return {
                ...state,
                notificationDetails: action.payload
            };
        }
        case ActionType.GET_NOTIFICATION_BY_ID_FAILURE: {

            return {
                ...state,
                notificationDetails: {}
            };
        }
        case ActionType.RESET_NOTIFICATION_DETAILS: {
            return {
                ...state,
                notificationDetails: {}
            };
        }

        default:
            return state
    }
}
