import { ActionType } from '../../actions/Helpers';
const initialState = {
    showLoader: false as boolean,
};
export const commonReducer = (state = initialState, action = {} as any) => {
    switch (action.type) {
        case ActionType.OPEN_SPINNER:
        case ActionType.SHOW_LOADER: {
            return {
                ...state,
                showLoader: true
            };
        }
        case ActionType.CLOSE_SPINNER:
        case ActionType.HIDE_LOADER: {
            return {
                ...state,
                showLoader:false
            };
        }

        default:
            return state;
    }
};
