import authService from './Auth.Service';
export const PORTAL_API_URL = (window as any).env.REACT_APP_BASE_URL;
export const getHeader = async () => {
  const accessToken = await authService.getToken();
  let headers: any = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`;
  }
  return headers;
};
