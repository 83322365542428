

import { Dispatch } from "redux";
import * as ApiVariables from '../../service/ApiVariables';
import { AppState } from '../../state/AppState';
import { Spinner } from '../../state/Spinner';
import { ActionType, IAppAction } from './Helpers';




export const getAllNotifications = () => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner("") })
    return new Promise((resolve, reject) => {
        api(ApiVariables.NotificationsApi.getNotificationsRecords).then((response: any) => {
            dispatch({ type: ActionType.SET_NOTIFICATIONS_LIST, payload: response });
            dispatch({ type: ActionType.CLOSE_SPINNER })
            resolve(response);
        }).catch((response: any) => {
            dispatch({ type: ActionType.CLOSE_SPINNER })
            reject(response);
        })
    })
};
export const getNextOrPreviousNotifications = (pageURL: string) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner("") })

    return new Promise((resolve, reject) => {
        ApiVariables.NotificationsApi.getNextOrPreviousNotifications.setPageURL = pageURL;
        api(ApiVariables.NotificationsApi.getNextOrPreviousNotifications).then((response: any) => {
            dispatch({ type: ActionType.NEXT_OR_PREVIOUS_NOTIFICATIONS_LIST, payload: response });
            dispatch({ type: ActionType.CLOSE_SPINNER })
            resolve(response);
        }).catch((response: any) => {
            dispatch({ type: ActionType.CLOSE_SPINNER })
            reject(response);
        })
    })
};
export const resetNotificationsGridData = (): IAppAction => {
    return { type: ActionType.SET_NOTIFICATIONS_LIST, payload: { results: [] } };
};

export const getNotificationById = (notificationId: string) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    dispatch({ type: ActionType.OPEN_SPINNER, payload: new Spinner("") })
    return new Promise((resolve, reject) => {
        ApiVariables.NotificationsApi.getNotificationRecordById.setNotificationId = notificationId;
        api(ApiVariables.NotificationsApi.getNotificationRecordById).then((response: any) => {
            dispatch({ type: ActionType.GET_NOTIFICATION_BY_ID_SUCCESS, payload: response });
            dispatch({ type: ActionType.CLOSE_SPINNER })
            resolve(response);
        }).catch((response: any) => {
            dispatch({ type: ActionType.GET_NOTIFICATION_BY_ID_FAILURE, payload: {} });
            dispatch({ type: ActionType.CLOSE_SPINNER })
            reject(response);
        })
    })
};

export const resetNotification = (): IAppAction => {
    return { type: ActionType.RESET_NOTIFICATION_DETAILS };
};
