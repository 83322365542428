export const RouteAccess = {
  Administrator: 'A',
  Assessor: 2,
  PropertyOwner: 3,
  All: 'U',
};
export const APIConstantList = {
  getFeeScheduleList:'payments/fee-schedule',
  resendEmail: '/resend-email/'
}

export const TokenStoreKey = 'auth-token';
