import FeeScheduleViewModel from '../../models/FeeSchedule/FeeScheduleViewModel';
import { ActionType } from './Helpers';

// get  Details List

export function fetchFeeScheduleList(param: string) {
  return {
    type: ActionType.GET_FEE_SCHEDULE_LIST_REQUEST,
    payload: param
  };
}

export function feeScheduleListRowPerPage(param: string) {
  return {
    type: ActionType.SET_FEE_SCHEDULE_LIST_ROW_PER_PAGE,
    payload: param
  };
}

export function fetchFeeScheduleListSuccess(response: any) {
  return {
    type: ActionType.GET_FEE_SCHEDULE_LIST_SUCCESS,
    payload: response
  };
}

export function fetchFeeScheduleListFailure() {
  return {
    type: ActionType.GET_FEE_SCHEDULE_LIST_FAILURE,
  };
}

// get  Details By id

export function fetchFeeScheduleBySerialNumber(param: string) {
  return {
    type: ActionType.GET_FEE_SCHEDULE_BY_ID_REQUEST,
    payload: param
  };
}

export function fetchFeeScheduleBySerialNumberSuccess(response: any) {
  return {
    type: ActionType.GET_FEE_SCHEDULE_BY_ID_SUCCESS,
    payload: response
  };
}

export function fetchFeeScheduleBySerialNumberFailure() {
  return {
    type: ActionType.GET_FEE_SCHEDULE_BY_ID_FAILURE,
  };
}


export function putUpdateFeeScheduleBySerialNumber(payload: FeeScheduleViewModel) {
  return {
    type: ActionType.PUT_FEE_SCHEDULE_BY_ID_REQUEST,
    payload: payload
  };
}

export function putUpdateFeeScheduleBySerialNumberSuccess(response: any) {
  return {
    type: ActionType.PUT_FEE_SCHEDULE_BY_ID_SUCCESS,
    payload: response
  };
}

export function putUpdateFeeScheduleBySerialNumberFailure() {
  return {
    type: ActionType.PUT_FEE_SCHEDULE_BY_ID_FAILURE,
  };
}


export function postSaveFeeScheduleRequest(payload: FeeScheduleViewModel) {
  return {
    type: ActionType.POST_FEE_SCHEDULE_SAVE_REQUEST,
    payload: payload
  };
}

export function postSaveFeeScheduleSuccess(response: any) {
  return {
    type: ActionType.POST_FEE_SCHEDULE_SAVE_SUCCESS,
    payload: response
  };
}

export function resendEmail(accountId: string) {
  return {
    type: ActionType.PUT_RESEND_EMAIL_REQUEST,
    payload: accountId
  };
}
