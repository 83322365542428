import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, Menu, MenuItem } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { CSSObject, styled, Theme, useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { renderRoutes } from 'react-router-config';
import { NavLink, Redirect, Route } from 'react-router-dom';
import { RouteAccess } from '../AppConstants';
import SideNavOptions from '../configs/SideNavOptions';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { ActionType } from '../store/actions/Helpers';
const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function SideMiniDrawer(props: any) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const { routes, history } = props;
  const { user } = useSelector((state: any) => state.auth);
  const [anchorElMenu, setAnchorElMenu] = React.useState(null);
  const dispatch = useDispatch();
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleClickOpenMenu = (event: any) => {
    setAnchorElMenu(event.currentTarget)
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const logoutAuth0 = () => {
    sessionStorage.clear();
    dispatch({ type: ActionType.LOGIN_OUT, payload: null });
    setAnchorElMenu(null);
    localStorage.clear();
    history.push(`/login`);
  };
  const locationActive = window.location.pathname;
  const updatedSideMenu = SideNavOptions.filter((menu: any) => menu.access === RouteAccess.All);
  if (!user) {
    return (<Box component="main" sx={{ flexGrow: 1, p: 1, width: '100%', marginTop: '50px' }}>
      {renderRoutes(routes?.filter((x: any) => (x.path === '/login' || x.path === '/')))}
      <Route path="*" component={() => (<Redirect to="/login" />)} />
      <ToastContainer
        icon={false}
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

    </Box >)
  } else {
    return (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography sx={{ flexGrow: 1 }} variant="h6" noWrap component="div">
              HPay Payment
            </Typography>
            <IconButton aria-label="show profile" color="inherit" aria-controls="simple-menu" aria-haspopup="true" onClick={(event) => handleClickOpenMenu(event)} >
              <span style={{ fontSize: '16px' }}>{user.userName}</span> <AccountCircle />
            </IconButton>
            <Menu style={{ top: '8px' }}
              id="simple-menu-open"
              anchorEl={anchorElMenu}
              keepMounted={true}
              open={Boolean(anchorElMenu)}
              onClose={() => setAnchorElMenu(null)}
            >
              <MenuItem onClick={() => logoutAuth0()}>Logout</MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          {updatedSideMenu.map((route: any, index: number) => {
            return (
              <NavLink
                activeStyle={{
                  fontWeight: "bold",
                }}
                activeClassName="active"
                style={{ textDecoration: 'none', color: 'black' }}
                key={index} exact={true} to={route.path} >
                <ListItem button={true}>
                  <ListItemIcon>
                    {route.icon()}
                  </ListItemIcon>
                  <ListItemText primary={route.title} />
                </ListItem>
              </NavLink>
            );
          })}
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 1, width: '90%' }}>
          <DrawerHeader />
          {user && renderRoutes(routes?.filter((x: any) => x.access === RouteAccess.All))}
          {(user && (locationActive === process.env.PUBLIC_URL || locationActive === process.env.PUBLIC_URL + '/' || locationActive === process.env.PUBLIC_URL + '/login')) && (
            <Route path="/"
              component={() => (
                <Redirect to="/fee-schedule-grid" />
              )}
            />
          )}
          {props.children}
        </Box>
        <ToastContainer
          icon={false}
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </Box>
    );
  }

}
