import history from '../../@history';
import axios from 'axios';
import { TokenStoreKey } from '../AppConstants';
export const PORTAL_API_URL = (window as any).env.REACT_APP_BASE_URL;
class AuthService {
  timerWarningId: any;
  timerLogoutId: any;
  timerLogout: any;

  getToken = async () => {
    const value = await localStorage.getItem(TokenStoreKey);
    return value;
  };

  getUser = async () => {
    // make api call to extract user details from token
    const accessToken = await this.getToken();
    const result = await axios.get(`${PORTAL_API_URL}/account/identity`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return result.data;
  };

  handleAuthenticationCallback = async (accessToken: string) => {
    // store token in localStorage
    localStorage.setItem(TokenStoreKey, JSON.stringify(accessToken));
    // set timer
    // this.setSessionTimer();
  };



  login = async (payload: any) => {
    // make api call to verify login and get access token,
    // call handle authentication callback with token
    try {
      const result = await axios.post(
        `${PORTAL_API_URL}/account/login`,
        payload,
        { headers: { 'Content-Type': 'application/json' } }
      );
      const accessToken = result.data;
      if (accessToken.redirectUrl) {
        history.push(accessToken.redirectUrl);
      }
      await this.handleAuthenticationCallback(accessToken);
      return 1;
    } catch (error) {
      console.log(error);
      return 0;
    }
  };

  register = async (payload: any) => {
    // make api call to verify login and get access token,
    // call handle authentication callback with token
    try {
      const result = await axios.post(
        `${PORTAL_API_URL}/account/register`,
        payload,
        { headers: { 'Content-Type': 'application/json' } }
      );
      return 1;
    } catch (error) {
      console.log(error);
      return 0;
    }
  };


  updatePassword = async (payload: any) => {
    try {
      await axios.post(`${PORTAL_API_URL}/account/updatepassword`, payload, {
        headers: { 'Content-Type': 'application/json' },
      });
      return 1;
    } catch (error) {
      return 0;
    }
  };

  resetPassword = async (email: string) => {
    try {
      await axios.post(
        `${PORTAL_API_URL}/account/forgetpassword/${email}`,
        null,
        { headers: { 'Content-Type': 'application/json' } }
      );
      return 1;
    } catch (error) {
      return 0;
    }
  };

  logout = async (id: string) => {
    try {
      const accessToken = await this.getToken();
      await axios.get(`${PORTAL_API_URL}/account/logout/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });
    } catch (error) { }
    clearInterval(this.timerLogout);
    // clear the token from localStorage
    localStorage.clear();
    history.push({
      pathname: '/login',
    });
  };
}

const instance = new AuthService();

export default instance;
