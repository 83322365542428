import * as React from 'react';
import { CircularProgress, DialogContentText, DialogContent, Dialog } from '@mui/material';
import { withStyles, WithStyles } from '@mui/styles';
const styles = (theme: any) => ({
    progress: {
        margin: theme.spacing(2),
    },
    content: {
        display: 'flex',
        alignItems: 'center'
    }
});

interface ISpinnerProps {
    message?: string;
    classes?: any;
}

class SpinnerDialog extends React.Component<ISpinnerProps & WithStyles<"progress">, {}> {

    public render() {
        return (

            <Dialog
                open={this.props.message !== null}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent className={this.props.classes.content}>
                    <CircularProgress className={this.props.classes.progress} />
                    <DialogContentText id="alert-dialog-description">
                        {this.props.message}
                    </DialogContentText>
                </DialogContent>

            </Dialog>
        );
    }
}



export default withStyles(styles)(SpinnerDialog) as any;
