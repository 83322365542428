import { combineReducers } from 'redux';
import { auth } from './Auth/Auth.Reducer'
import { feeScheduleReducer } from './FeeSchedule/FeeSchedule.Reducer'
import { commonReducer } from './Common/Common.Reducer';
import { notificationsReducer } from './Notifications/Notifications.Reducer'
const rootReducer = combineReducers({
    auth: auth,
    feeSchedule: feeScheduleReducer,
    commonReducer,
    notification: notificationsReducer
});
export default rootReducer;
