import { ActionType, IAppAction } from '../../actions/Helpers';

const initialState = {
  user: null,
  authInfo: undefined,
};
export const auth = (state = initialState, action = {} as IAppAction) => {
  switch (action.type) {
    case ActionType.LOGIN_SUCCESS: {
      return {
        ...state,
        authInfo: ['U', 'A'],
        user: action.payload
      };
    }
    case ActionType.LOGIN_FAILURE: {
      return {
        ...state,
        user: null
      };
    }
    case ActionType.LOGIN_OUT: {
      return {
        ...state,
        user: null
      };
    }

    default:
      const loginDetails = sessionStorage.getItem('loginDetails');
      if (loginDetails) {
        const parseData = JSON.parse(loginDetails);
        return {
          ...state,
          authInfo: ['U', 'A'],
          user: parseData
        };

      }
      return state;
  }
};
