import {
  Button, Paper, Table, TableBody, TableCell,
  TableHead, TablePagination, TableRow, Theme, Typography
} from '@mui/material';
import { withStyles } from '@mui/styles';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { RouteComponentProps } from "react-router-dom";
import { bindActionCreators, Dispatch } from 'redux';
import * as NotificationActions from '../../store/actions/Notification.Actions';
import { process, State } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column, GridDataStateChangeEvent, GridRowDoubleClickEvent } from "@progress/kendo-react-grid";

const classNames = require('classnames');

interface IPageProps {
  classes: any,
  history: any,
  getAllNotifications: () => void;
  getNextOrPreviousNotifications: (pageURL: string) => void;
  resetNotifications: () => void;
  notificationsList: any[],
  previous: any,
  next: any
}
interface IPageState {
  anchorEl: any;
  previous: any,
  next: any,
  dataState: State;
}

export class TransactionList extends React.Component<RouteComponentProps & IPageProps, IPageState> {
  constructor(props: any) {
    super(props);
    this.state = {
      anchorEl: null,
      previous: null,
      next: null,
      dataState:{
        sort: [{ field: "topic"}],
        take: 10,
        skip: 0,
      }
    }
  }
  public async componentWillMount() {
    this.props.getAllNotifications();
  }
  private handleChangePage = (event: any, page: number) => {
    console.log('')
  };

  private onEnterKeyPress = (event: any) => {
    if (event.keyCode === 13 || event.keyCode === '13' || event.key === 'Enter') {
      console.log('')
    }
  }

  private editRecord = (data: any) => {
    this.props.history.push(`/view-notification/` + data.id);
  }

  private handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  private handleClose = () => {
    this.setState({ anchorEl: null, });
  }
  private handleSearchBox = (event: any) => {
    const value = event.target.value;
    this.setState({
      ...this.state,
      [event.target.name]: value
    });
  }
  nextOrPrevious = (pageURl: string) => {
    this.props.resetNotifications();
    this.props.getNextOrPreviousNotifications(pageURl);
  }
  render() {
    const { anchorEl } = this.state;
    const { classes, notificationsList, next, previous } = this.props;
    console.log(notificationsList);
    return (
      <Paper className={classNames(classes.paper, classes.container, 'container')} elevation={5} square={false}>
        {/* <Grid item={true} xs={12} sm={1} style={{ paddingLeft: 8, display: 'flex', alignItems: 'center', margin: '5px' }}> */}
          <Typography component={'h2'} variant="body1" style={{  paddingLeft: 8, margin: '5px' ,fontWeight: 'bolder', fontSize: 18 }}>Notifications</Typography>
        {/* </Grid> */}
        <hr style={{ color: 'rgb(224 224 224)', border: '1px solid' }} />
        <Typography component="div" className={classes.tableContainer}>
        <Grid
                    resizable={true}
                    sortable={true}
                    filterable={true}
                    style={{ height: "400px" }}
                    data={process(notificationsList, this.state.dataState)}
                    {...this.state.dataState}
                    onRowDoubleClick={(e:GridRowDoubleClickEvent)=>{
                        console.log(e);
                        this.editRecord(e.dataItem)
                    }}
                    onDataStateChange={(e: GridDataStateChangeEvent) => {
                        this.setState({ dataState: e.dataState });
                      }}
                >                   
                    <Column field="topic" title="Topic" minResizableWidth={90}/>
                    <Column field="payload.resource" title="Resource" />
                    <Column field="payload.currency" title="Currency" />
                    <Column field="payload.fee_amount" title="Fees" filter='numeric' />
                    <Column field="payload.amount" title="Gross" filter='numeric'/>
                    <Column field="payload.status" title="Status"filter='numeric'/>
                    <Column field="payload.pending_reasons[0].reason_message"  title="Reason"/>
                </Grid>
          

          {/* <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeaderBg}>Topic</TableCell>
                <TableCell className={classes.tableHeaderBg}>Resource</TableCell>
                <TableCell className={classes.tableHeaderBg}>Currency</TableCell>
                <TableCell className={classes.tableHeaderBg}>Fees</TableCell>
                <TableCell className={classes.tableHeaderBg}>Gross Amount</TableCell>
                <TableCell className={classes.tableHeaderBg}>Status</TableCell>
                <TableCell className={classes.tableHeaderBg}>Reason Message</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {(Array.isArray(notificationsList) && notificationsList.length > 0) &&
                notificationsList.map((data: any, index: number) => {
                  return (
                    <TableRow key={`notifications${index}`} onDoubleClick={() => this.editRecord(data)}>
                    <TableCell style={{ textTransform: 'capitalize' }}>{data?.topic || '--'}</TableCell>
                      <TableCell style={{ textTransform: 'capitalize' }}>{data?.payload?.resource || '--'}</TableCell>
                      <TableCell>{data?.payload?.currency || '--'}</TableCell>
                      <TableCell>$ {data?.payload?.fee_amount || '0'}</TableCell>
                      <TableCell>$ {data?.payload?.amount || '0'}</TableCell>
                      <TableCell style={{ textTransform: 'capitalize' }}>{data?.payload?.status || '--'}</TableCell>
                      <TableCell>{data?.payload?.pending_reasons?.length > 0 ? data?.payload?.pending_reasons[0]?.reason_message : '--'}</TableCell>

                    </TableRow>
                  );
                })}
            </TableBody>
          </Table> */}
          <Typography component={'div'} style={{ display: 'flex', justifyContent: 'end', paddingRight: 8, paddingTop: 8 }}>
            {next !== null && <Button style={{ margin: 8 }} onClick={() => this.nextOrPrevious(next)} variant="outlined" size="medium" >Next</Button>}
            {previous !== null && <Button style={{ margin: 8 }} variant="outlined" onClick={() => this.nextOrPrevious(previous)} size="medium">Previous</Button>}
          </Typography>

        </Typography>
      </Paper>)


  }
}
const styles = (theme: Theme) => ({
  paper: {
    overflow: 'hidden',
    color: theme.palette.text.secondary,
  },
  container: {
    [theme.breakpoints.only('xs')]: {
      marginBottom: '30%',
    },
    [theme.breakpoints.only('sm')]: {
      marginBottom: '12%',
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: '10%',
    },
  },
  tableContainer: {
    padding: '1px',
    overflow: 'auto'
  },
  iconPointer: {
    cursor: 'pointer'
  },
  dialogContainer: {
    width: '50%',
    height: '50%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
      margin: 0,
      maxWidth: '100%',
      maxHeight: 'none',
      borderRadius: 0,
    },
  },
  previewChip: {
    minWidth: 160,
    maxWidth: 210
  },
  tableHeaderBg: {
    backgroundColor: '#1976d2',
    fontWeight: 700,
  }
})
const mapStateToProps = (state: any) => {
  return {
    notificationsList: state.notification?.notificationsList,
    previous: state.notification?.previous,
    next: state.notification?.next
  }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({
    getAllNotifications: NotificationActions.getAllNotifications,
    getNextOrPreviousNotifications: NotificationActions.getNextOrPreviousNotifications,
    resetNotifications: NotificationActions.resetNotificationsGridData
  }, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles as any)(TransactionList) as any) as any);