import { RouteAccess } from '../AppConstants';
import FeeSchedule from '../pages/FeeSchedule/FeeSchedule';
import FeeScheduleForm from '../pages/FeeSchedule/FeeScheduleForm';
import FeeScheduleGrid from '../pages/FeeSchedule/FeeScheduleGrid'
import SignIn from '../pages/Auth/SignIn';
import Notifications from '../pages/Notifications/NotificationsList';
import NotificationForm from '../pages/Notifications/NotificationForm';
const routes = [
  {
    path: '/',
    component: SignIn,
    exact: true,
    access: RouteAccess.All,
  },
  {
    path: '/login',
    component: SignIn,
    exact: true,
    access: RouteAccess.All,
  },
  {
    path: '/payment-link',
    component: FeeSchedule,
    exact: true,
    access: RouteAccess.All,
  },
  {
    path: '/fee-schedule-grid',
    component: FeeScheduleGrid,
    exact: true,
    access: RouteAccess.All,
  },
  {
    path: '/fee-schedule',
    component: FeeSchedule,
    exact: true,
    access: RouteAccess.All,
  },
  {
    path: '/fee-schedule-create',
    component: FeeScheduleForm,
    exact: true,
    access: RouteAccess.All,
  },
  {
    path: '/edit-fee-schedule/:id',
    component: FeeScheduleForm,
    exact: true,
    access: RouteAccess.All,
  },
  {
    path: '/payments',
    component: FeeSchedule,
    exact: true,
    access: RouteAccess.All,
  },
  {
    path: '/merchant',
    component: FeeSchedule,
    exact: true,
    access: RouteAccess.All,
  },
  {
    path: '/notifications',
    component: Notifications,
    access: RouteAccess.All,
    exact: true,
  },
  {
    path: '/view-notification/:id',
    component: NotificationForm,
    access: RouteAccess.All,
    exact: true,
  }
  
  
];

export default routes;
