import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import {
  createStyles, Theme
} from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators, Dispatch } from 'redux';
import SimpleReactValidator from 'simple-react-validator';
import * as AuthActions from '../../store/actions/Auth.Actions'
import { toast } from 'react-toastify';
interface ISignInProps {
  style?: any;
  classes: any;
  root?: any;
  login: (body: any) => any;
  history?: any;
}
interface IPageState {
  userName: string;
  password: string;
}
const styles = (theme: Theme) => createStyles({
  headerContainer: {
    textAlign: 'center',
  },

  marginBottom5: {
    marginBottom: '5px',
  },
  avatar: {
    display: 'inline-flex',
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  forgotPassword: {
    textAlign: 'right',
    padding: '12px'
  },
  buttonMargin: {
    marginBottom: '10px',
    marginTop: '10px',
  },
  linkText: {
    color: '#0b22c3',
    cursor: 'pointer'
  },
  signUpAccount: {
    textAlign: 'center'
  },
  rememberText: {
    fontSize: '12px',
  },
});

class SignIn extends Component<ISignInProps, IPageState> {
  public validator: SimpleReactValidator = new SimpleReactValidator();
  constructor(props: any) {
    super(props);
    this.state = {
      userName: '',
      password: '',
    }
  }
  public handleLogin = async (event: any) => {
    event.preventDefault();
    if (this.validator.allValid()) {
      const payload = {
        "userName": this.state.userName,
        "password": this.state.password
      }
      await this.props.login(payload).then((response: any) => {
        this.props.history.push('/fee-schedule-grid');
      })
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  };

  public onHandleChange = (event: any, type: string) => {
    if (type === 'password') {
      this.setState({ password: event.target.value })
    } else {
      this.setState({ userName: event.target.value })
    }

  }

  public componentWillMount = () => {
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }
  public render() {
    const { classes, style, root } = this.props;
    const { userName, password } = this.state;
    this.validator.purgeFields();
    return (
      <div className={root} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Container component="main" maxWidth="xs" className={style}>
          <CssBaseline />
          <Grid container={true}>
            <Grid item={true} xs={12} sm={12} className={classes.headerContainer}>
              <Typography component="h1" variant="h5">
                Sign in
              </Typography>
            </Grid>
            <Grid item={true} xs={12} sm={12}>
              <Grid container={true}>
                <Grid item={true} xs={12} sm={12}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required={true}
                    fullWidth={true}
                    onChange={(e: any) => this.onHandleChange(e, 'userName')}
                    onBlur={() => this.validator.showMessageFor('userName')}
                    id="userName"
                    value={userName}
                    label="UserName"
                    name="userName"
                    autoComplete="userName"
                  />
                  {this.validator.message('userName', userName, 'required')}
                </Grid>
                <Grid item={true} xs={12} sm={12}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required={true}
                    fullWidth={true}
                    value={password}
                    name="password"
                    label="Password"
                    onChange={(e) => this.onHandleChange(e, 'password')}
                    onBlur={() => this.validator.showMessageFor('password')}
                    type="password"
                    autoComplete="password"
                    id="password"
                  />
                  {this.validator.message('password', password, 'required')}
                </Grid>
                <Grid item={true} xs={12} sm={12}>
                  <Grid container={true} >
                    <Grid item={true} xs={6} sm={6}>
                      <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                        className={classes.rememberText}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item={true} xs={12} sm={12} className={classes.buttonMargin}>
                  <Button
                    type="submit"
                    fullWidth={true}
                    variant="contained"
                    style={{ textTransform: 'capitalize' }}
                    onClick={(event: any) => this.handleLogin(event)}
                    color="primary"
                  >
                    Sign In
                  </Button>
                </Grid>

              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>

    );
  }
}


const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({
    login: AuthActions.login,
  }, dispatch);
};


export default withRouter(connect(null, mapDispatchToProps)(withStyles(styles)(SignIn)) as React.ComponentType<any>);
