import { Action } from "redux";

export enum ActionType {
    SHOW_LOADER,
    HIDE_LOADER,
    OPEN_SPINNER,
    CLOSE_SPINNER,
    
    GET_LOGIN_USER_DETAILS_REQUEST,
    GET_LOGIN_USER_DETAILS_SUCCESS,
    GET_LOGIN_USER_DETAILS_FAILURE,

    GET_FEE_SCHEDULE_LIST_REQUEST,
    GET_FEE_SCHEDULE_LIST_SUCCESS,
    GET_FEE_SCHEDULE_LIST_FAILURE,

    GET_FEE_SCHEDULE_BY_ID_REQUEST,
    GET_FEE_SCHEDULE_BY_ID_SUCCESS,
    GET_FEE_SCHEDULE_BY_ID_FAILURE,

    PUT_FEE_SCHEDULE_BY_ID_REQUEST,
    PUT_FEE_SCHEDULE_BY_ID_SUCCESS,
    PUT_FEE_SCHEDULE_BY_ID_FAILURE,

    POST_FEE_SCHEDULE_SAVE_REQUEST,
    POST_FEE_SCHEDULE_SAVE_SUCCESS,
    POST_FEE_SCHEDULE_SAVE_FAILURE,

    SET_FEE_SCHEDULE_LIST_ROW_PER_PAGE,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGIN_OUT,

    SET_NOTIFICATIONS_LIST,
    NEXT_OR_PREVIOUS_NOTIFICATIONS_LIST,
    GET_NOTIFICATION_BY_ID_SUCCESS,
    GET_NOTIFICATION_BY_ID_FAILURE,
    RESET_NOTIFICATION_DETAILS,

    PUT_RESEND_EMAIL_REQUEST,
 
}

export interface IAppAction extends Action<ActionType> {
    payload?: any;
}