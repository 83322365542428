import { ActionType, IAppAction } from './Helpers';
import { Dispatch } from "redux";
import { AppState } from '../../state/AppState';
import { toast } from 'react-toastify';

export const login = (body: any) => (dispatch: Dispatch<IAppAction>, getState: () => AppState, { api }: { api: any }) => {
    dispatch({ type: ActionType.SHOW_LOADER })
    return new Promise(async (resolve, reject) => {
        if (body.userName === 'hpay0126!' && body.password === 'Happy days are here again!') {
            const response = { login: true, userName: 'hpay0126!' }
            sessionStorage.setItem('loginDetails', JSON.stringify(response))
            toast('logged in successfully', { type: 'success' });
            dispatch({ type: ActionType.LOGIN_SUCCESS, payload: response });
            resolve(response);
        } else {
            toast('userName or password is worng', { type: 'error' });
            dispatch({ type: ActionType.LOGIN_FAILURE, payload: null });
            reject('userName or password is worng')

        }
        dispatch({ type: ActionType.HIDE_LOADER })
    })
};

