import { Typography } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { Theme, ThemeProvider } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import AppContext from '../AppContext';
import SpinnerDialog from '../components/Loader/Spinner';
import them from '../themes';
import NavigationScroll from './NavigationScroll';
import Header from './SideMiniDrawer';
import { useSelector, useDispatch } from 'react-redux';
const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        '&.boxed': {
            maxWidth: 1280,
            margin: '0 auto',
        },
        '&.container': {
            '& .container': {
                maxWidth: 1120,
                width: '100%',
                margin: '0 auto',
            },
            '& .navigation': {},
        },
    },
    content: {
        overflow: 'auto',
        flexDirection: 'column',
        '-webkit-overflow-scrolling': 'touch',
        backgroundColor: '#f9f9f9',
    },
}));

const Layout = (props: any) => {
    const classes = useStyles();
    const isLoading = false;
    const { showLoader } = useSelector((state: any) => state.commonReducer);
    return (
        <ThemeProvider theme={them}>
            <CssBaseline />
            <AppContext.Consumer>
                {(appContext: any) => (
                    <NavigationScroll>
                        <div className={classes.root}>
                            {(isLoading || showLoader) && <SpinnerDialog />}
                            <Header routes={appContext.routes} history={props.history}/>
                        </div>
                    </NavigationScroll>
                )}
            </AppContext.Consumer>
        </ThemeProvider>
    );
}

export default Layout;