import { ActionType } from './Helpers';
export function showLoader() {
  return {
    type: ActionType.SHOW_LOADER
  };
}

export function hideLoader() {
  return {
    type: ActionType.HIDE_LOADER
  };
}
