import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
const UnWiredThem = createTheme({
    palette: {
        mode: 'light',
        primary: {
            light: '#7ab1e6',
            main: '#4682b4',
            dark: '#005684',
            contrastText: '#fff',
        },
        secondary: {
            light: '#515a65',
            main: '#28313b',
            dark: '#000815',
            contrastText: '#fff',
        },
        error: {
            main: red.A400,
        },
        background: {
            default: '#f3f3f6',
        },
    },
});

export default UnWiredThem;