import * as React from 'react';
import { withStyles } from '@mui/styles';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { FormControl, InputLabel, Input, Button, Grid, Typography, Paper, Container, Theme } from '@mui/material';
import SimpleReactValidator from 'simple-react-validator';
import FeeScheduleViewModel from '../../models/FeeSchedule/FeeScheduleViewModel';
import * as FeeScheduleAction from '../../store/actions/FeeSchedule.Actions';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
const classNames = require('classnames');

interface IPageProps {
    classes?: any,
    history: any;
    match: any;
    feeScheduleDetails: FeeScheduleViewModel,
    getFeeScheduleDetailsBySerialNumber: (accountId: string) => void;
    resetFeeScheduleDetailsBySerialNumber: () => void;
    putFeeScheduleDetails: (payload: any) => void;
    postFeeScheduleDetails: (payload: any) => void;
    resendEmail: (accountId: string) => any;
    updateFeeScheduleDetails: FeeScheduleViewModel;
    saveFeeScheduleDetails: FeeScheduleViewModel;
    saveFeeScheduleDetailsLoading: boolean
    updateFeeScheduleDetailsLoading: boolean
}
interface IPageState extends FeeScheduleViewModel {
    isEdit?: boolean
}

class FeeScheduleForm extends React.Component<RouteComponentProps & IPageProps, IPageState> {
    public validator: SimpleReactValidator = new SimpleReactValidator();

    constructor(props: any) {
        super(props);
        this.state = {
            accountId: '',
            name: '',
            cardholder_ic_rate: 2.9,
            cardholder_tran_rate: 0.30,
            merchant_tran_rate: 0.15,
            merchant_ic_rate: 3.5,
            ach_ic_rate: 1.00,
            ach_tran_rate: 0.25,
            isEdit: false,
            merchant_icplus_rate: 1.75,
            merchant_icplus_tran_rate: 0.15,
            cardholder_icplus_rate: 1.25,
            cardholder_icplus_tran_rate: 0.10,
            isICP: false,
            excludeAmex: false,
            excludeGPay: false,
            pushnotify:false,
        }
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this, messages: {
                integer: "Please enter only number"
            }
        })
    }

    public componentDidMount() {
        const serialNumber = this.props.match.params.id;
        const pathname = this.props.match.path;
        if (pathname === "/edit-fee-schedule/:id" && serialNumber) {
            this.setState({ isEdit: true })
            this.props.getFeeScheduleDetailsBySerialNumber(serialNumber)
        } else {
            this.props.resetFeeScheduleDetailsBySerialNumber();
            this.setState({ isEdit: false })
        }

    }

    public componentDidUpdate(prevProps: IPageProps, prevState: IPageState) {

        if (this.props.feeScheduleDetails?.serialNumber !== undefined && (this.props.feeScheduleDetails?.serialNumber !== prevState.serialNumber)) {
            const { feeScheduleDetails } = prevProps;
            this.setState({ ...feeScheduleDetails, isEdit: true });
        }

    }

    componentWillUnmount() {
        this.props.resetFeeScheduleDetailsBySerialNumber();
    }

    private onhandleChangeForm = (event: any) => {
        const value = event.target.value;
        this.setState({
            ...this.state,
            [event.target.name]: value
        });
    }
    private resendEmail = () => {
        const { accountId } = this.state;
        this.props.resendEmail(accountId || '');
    }
    private handleSubmitDetails = () => {
        if (this.validator.allValid()) {
            const payload: FeeScheduleViewModel = this.state;
            payload.accountId = this.state.accountId;
            payload.name = this.state.name;
            payload.serialNumber = this.state.serialNumber
            payload.cardholder_ic_rate = this.state.cardholder_ic_rate !== undefined ? +this.state.cardholder_ic_rate : 0;
            payload.cardholder_tran_rate = this.state.cardholder_tran_rate !== undefined ? +this.state.cardholder_tran_rate : 0;
            payload.merchant_tran_rate = this.state.merchant_tran_rate !== undefined ? +this.state.merchant_tran_rate : 0;
            payload.merchant_ic_rate = this.state.merchant_ic_rate !== undefined ? +this.state.merchant_ic_rate : 0;
            payload.ach_ic_rate = this.state.ach_ic_rate !== undefined ? +this.state.ach_ic_rate : 0;
            payload.ach_tran_rate = this.state.ach_tran_rate !== undefined ? +this.state.ach_tran_rate : 0;
            payload.cardholder_icplus_rate = this.state.cardholder_icplus_rate !== undefined ? +this.state.cardholder_icplus_rate : 0;
            payload.cardholder_icplus_tran_rate = this.state.cardholder_icplus_tran_rate !== undefined ? +this.state.cardholder_icplus_tran_rate : 0;
            payload.isICP = this.state.isICP !== undefined ? this.state.isICP : false;
            payload.excludeAmex = this.state.excludeAmex !== undefined ? this.state.excludeAmex : false;
            payload.excludeGPay = this.state.excludeGPay !== undefined ? this.state.excludeGPay : false;
            payload.pushnotify = this.state.pushnotify !== undefined ? this.state.pushnotify : false;
            
            if (this.state.isEdit) {
                this.props.putFeeScheduleDetails(payload)

            } else {
                this.props.postFeeScheduleDetails(payload)
            }
        } else {
            this.validator.showMessages();
        }
    }

    private handleCancel = () => {
        this.props.history.push('/fee-schedule-grid');
    }

    private handleChange = (event: any) => {
        this.setState({ isICP: event.target.checked })
    }
    private handlePushNotifyChange = (event: any) => {
        this.setState({ pushnotify: event.target.checked })
    }
    private handleChangeAmex = (event: any) => {
        this.setState({ excludeAmex: event.target.checked })
    }
    private handleChangeExcludeGPay = (event: any) => {
        this.setState({ excludeGPay: event.target.checked })
    }


    public render() {
        const isEditScreen = this.props.match.path === "/edit-fee-schedule/:id";
        this.validator.purgeFields();
        const { classes, } = this.props;
        const { accountId, isICP, serialNumber,
            excludeAmex,pushnotify,
            excludeGPay,
            cardholder_icplus_rate,
            cardholder_icplus_tran_rate, name,
            cardholder_ic_rate, merchant_tran_rate,
            cardholder_tran_rate, merchant_ic_rate,
            ach_ic_rate, ach_tran_rate } = this.state;
        return (
            <Paper className={classNames(classes.paper)} elevation={5} square={false}>
                <Typography component="div" className={classes.sectionTitle} style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                    <Typography component="p" align='center'>Fee Schedule</Typography>
                </Typography>
                <Grid container={true} spacing={4} style={{ marginTop: '25px' }}>
                    <Container maxWidth="lg">
                        <Grid item={true} xs={12} sm={12}>
                            <Grid container={true} spacing={3}>
                                <Grid item={true} xs={12} sm={6}>
                                    <FormControl required={false} fullWidth={true} className={classes.field}>
                                        <InputLabel htmlFor="serialNumber">Caterease Serial Number</InputLabel>
                                        <Input value={serialNumber ? serialNumber : ''}  onChange={this.onhandleChangeForm} id="serialNumber" name="serialNumber"
                                            onBlur={() => this.validator.showMessageFor('serialNumber')} />
                                        {this.validator.message('serialNumber', serialNumber, 'required')}
                                    </FormControl>
                                </Grid>
                                <Grid item={true} xs={12} sm={6}>
                                    <FormControl required={true} fullWidth={true} className={classes.field}>
                                        <InputLabel htmlFor="accountId">AccountId</InputLabel>
                                        <Input value={accountId ? accountId : ''} onChange={this.onhandleChangeForm} id="accountId" name="accountId"
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item={true} xs={12} sm={6}>
                                    <FormControlLabel control={<Checkbox checked={isICP} onChange={(event) => this.handleChange(event)} />} label="IsICP" />
                                </Grid>
                                <Grid item={true} xs={12} sm={6}>
                                    <FormControlLabel control={<Checkbox checked={pushnotify} onChange={(event) => this.handlePushNotifyChange(event)} />} 
                                    label="Push Notification" />
                                </Grid>
                                
                                <Grid item={true} xs={12} sm={6}>
                                    <FormControlLabel control={<Checkbox checked={excludeAmex} onChange={(event) => this.handleChangeAmex(event)} />} label="Exclude Amex" />
                                </Grid>
                                <Grid item={true} xs={12} sm={6}>
                                    <FormControlLabel control={<Checkbox checked={excludeGPay} onChange={(event) => this.handleChangeExcludeGPay(event)} />} label="Exclude GPay" />
                                </Grid>
                                <Grid item={true} xs={12} sm={6}>
                                    <FormControl required={true} fullWidth={true} className={classes.field}>
                                        <InputLabel htmlFor="name">Name</InputLabel>
                                        <Input value={name ? name : ''} onChange={this.onhandleChangeForm} id="name" name="name"
                                            onBlur={() => this.validator.showMessageFor('name')}
                                        />
                                        {this.validator.message('name', name, 'required')}
                                    </FormControl>
                                </Grid>
                                <Grid item={true} xs={12} sm={6}>
                                    <FormControl required={true} fullWidth={true} className={classes.field}>
                                        <InputLabel htmlFor="cardholder_ic_rate">Cardholder Ic Rate %</InputLabel>
                                        <Input value={cardholder_ic_rate} onChange={this.onhandleChangeForm} type="number"
                                            id="cardholder_ic_rate" name="cardholder_ic_rate" disabled={isICP}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item={true} xs={12} sm={6}>
                                    <FormControl required={true} fullWidth={true} className={classes.field}>
                                        <InputLabel htmlFor="cardholder_tran_rate">Cardholder Tran Rate $</InputLabel>
                                        <Input value={cardholder_tran_rate} onChange={this.onhandleChangeForm} type="number"
                                            id="cardholder_tran_rate" name="cardholder_tran_rate"
                                            disabled={isICP} />
                                    </FormControl>
                                </Grid>
                                <Grid item={true} xs={12} sm={6}>
                                    <FormControl required={true} fullWidth={true} className={classes.field}>
                                        <InputLabel htmlFor="merchant_ic_rate">Merchant Ic Rate %</InputLabel>
                                        <Input value={merchant_ic_rate} onChange={this.onhandleChangeForm} type="number" id="merchant_ic_rate" name="merchant_ic_rate"
                                            onBlur={() => this.validator.showMessageFor('merchant_ic_rate')} disabled={isICP}
                                        />
                                        {this.validator.message('merchant_ic_rate', merchant_ic_rate, 'required')}
                                    </FormControl>
                                </Grid>
                                <Grid item={true} xs={12} sm={6}>
                                    <FormControl required={true} fullWidth={true} className={classes.field}>
                                        <InputLabel htmlFor="merchant_tran_rate">Merchant Tran Rate $</InputLabel>
                                        <Input value={merchant_tran_rate} onChange={this.onhandleChangeForm} type="number" id="merchant_tran_rate" name="merchant_tran_rate"
                                            onBlur={() => this.validator.showMessageFor('merchant_tran_rate')} disabled={isICP}
                                        />
                                        {this.validator.message('merchant_tran_rate', merchant_tran_rate, 'required')}

                                    </FormControl>
                                </Grid>
                                <Grid item={true} xs={12} sm={6}>
                                    <FormControl required={true} fullWidth={true} className={classes.field}>
                                        <InputLabel htmlFor="ach_ic_rate">Ach Ic Rate %</InputLabel>
                                        <Input value={ach_ic_rate} onChange={this.onhandleChangeForm} type="number" id="ach_ic_rate" name="ach_ic_rate"
                                            onBlur={() => this.validator.showMessageFor('ach_ic_rate')}
                                        />
                                        {this.validator.message('ach_ic_rate', ach_ic_rate, 'required')}
                                    </FormControl>
                                </Grid>
                                <Grid item={true} xs={12} sm={6}>
                                    <FormControl required={true} fullWidth={true} className={classes.field}>
                                        <InputLabel htmlFor="ach_tran_rate">Ach Tran Rate $</InputLabel>
                                        <Input value={ach_tran_rate} onChange={this.onhandleChangeForm} type="number" id="ach_tran_rate" name="ach_tran_rate"
                                            onBlur={() => this.validator.showMessageFor('ach_tran_rate')}
                                        />
                                        {this.validator.message('ach_tran_rate', ach_tran_rate, 'required')}
                                    </FormControl>
                                </Grid>

                                <Grid item={true} xs={12} sm={6}>
                                    <FormControl required={true} fullWidth={true} className={classes.field}>
                                        <InputLabel htmlFor="cardholder_icplus_rate">Cardholder Ic Plus Rate %</InputLabel>
                                        <Input value={cardholder_icplus_rate} onChange={this.onhandleChangeForm} type="number" id="cardholder_icplus_rate" name="cardholder_icplus_rate"
                                            onBlur={() => this.validator.showMessageFor('cardholder_icplus_rate')} disabled={!isICP}
                                        />
                                        {this.validator.message('cardholder_icplus_rate', cardholder_icplus_rate, 'required')}
                                    </FormControl>
                                </Grid>
                                <Grid item={true} xs={12} sm={6}>
                                    <FormControl required={true} fullWidth={true} className={classes.field}>
                                        <InputLabel htmlFor="cardholder_icplus_tran_rate">Cardholder Ic Plus Tran Rate $</InputLabel>
                                        <Input value={cardholder_icplus_tran_rate} onChange={this.onhandleChangeForm} type="number" id="cardholder_icplus_tran_rate" name="cardholder_icplus_tran_rate"
                                            onBlur={() => this.validator.showMessageFor('cardholder_icplus_tran_rate')} disabled={!isICP}
                                        />
                                        {this.validator.message('cardholder_icplus_tran_rate', cardholder_icplus_tran_rate, 'required')}
                                    </FormControl>
                                </Grid>
                                {isEditScreen &&
                                    <Grid item={true} xs={12} sm={6}>
                                        <Button
                                            onClick={this.resendEmail}
                                            variant="text"
                                            color="primary"
                                            style={{ backgroundColor: 'green', textAlign: "center", color: '#fafafa' }}
                                            className={classes.buttonCommon}
                                        >Resend E-Mail</Button>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                        <Grid item={true} xs={12} sm={12} style={{ paddingTop: 20 }}>
                            <Grid container={true} spacing={3} className={classes.alignButton}>
                                <Grid item={true} xs={12} sm={6} >
                                    <Button
                                        onClick={this.handleSubmitDetails}
                                        variant="text"
                                        color="primary"
                                        style={{ backgroundColor: '#bbdefb', textAlign: "center", color: '#6d6c6c' }}
                                        className={classes.buttonCommon}
                                    >Save</Button>
                                </Grid>
                                <Grid item={true} xs={12} sm={6}  >
                                    <Button
                                        onClick={this.handleCancel}
                                        variant="text"
                                        color="secondary"
                                        style={{ backgroundColor: '#f55089', textAlign: "center", color: '#6d6c6c' }}
                                        className={classes.buttonCommon}
                                    >Cancel</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
            </Paper>)
    }
}
const styles = (theme: Theme) => ({
    paper: {
        padding: theme.spacing(3),
        color: theme.palette.text.secondary,
        marginBottom: '6%',
        [theme.breakpoints.only('xs')]: {
            marginBottom: '30%',
        },
        [theme.breakpoints.only('sm')]: {
            marginBottom: '12%',
        },
        [theme.breakpoints.up('md')]: {
            marginBottom: '10%',
        },
    },

    formTitle: {
        fontWeight: 'bold'
    },
    buttonCommon: {
        [theme.breakpoints.between('xs', 'sm')]: {
            width: '100%',
        },
        [theme.breakpoints.between('md', 'xl')]: {
            width: '50%'
        },
    },
    alignButton: {
        marginBottom: '4px',
        [theme.breakpoints.between('md', 'xl')]: {
            flexDirection: 'row-reverse',
            textAlign: 'center',
        },
    },
    sectionTitle: {
        display: 'flex',

        marginBottom: "40px"
    }

});
const mapStateToProps = (state: any) => {
    const { getFeeScheduleByAccountId,
        updateFeeScheduleDetails,
        saveFeeScheduleDetails,
        saveFeeScheduleDetailsLoading,
        updateFeeScheduleDetailsLoading,
    } = state.feeSchedule;
    return {
        feeScheduleDetails: getFeeScheduleByAccountId,
        updateFeeScheduleDetails,
        saveFeeScheduleDetails,
        saveFeeScheduleDetailsLoading,
        updateFeeScheduleDetailsLoading,
    }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        getFeeScheduleDetailsBySerialNumber: FeeScheduleAction.fetchFeeScheduleBySerialNumber,
        putFeeScheduleDetails: FeeScheduleAction.putUpdateFeeScheduleBySerialNumber,
        postFeeScheduleDetails: FeeScheduleAction.postSaveFeeScheduleRequest,
        resetFeeScheduleDetailsBySerialNumber: FeeScheduleAction.fetchFeeScheduleBySerialNumberFailure,
        resendEmail: FeeScheduleAction.resendEmail
    }, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles as any)(FeeScheduleForm) as any) as any);

