
export const NotificationsApi = {
    getNotificationsRecords: {
        url: 'payment/notifications',
        method: 'get',
        get apiEndPoint() {
            return this.url;
        },
    },
    getNextOrPreviousNotifications: {
        url: 'payment/next-or-previous',
        method: 'get',
        pageURL: '',
        set setPageURL(pageUrl: string) {
            this.pageURL = pageUrl;
        },
        get apiEndPoint() {
            return this.url + this.pageURL;
        },
    },
    getNotificationRecordById: {
        url: 'payment/notifications/',
        method: 'get',
        notificationId: '',
        set setNotificationId(notificationId: string) {
            this.notificationId = notificationId;
        },
        get apiEndPoint() {
            return this.url + this.notificationId;
        },
    },
}