import { applyMiddleware, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './reducers';
import FeeScheduleApiMiddleware from '../service/FeeSchedule.Service';
import { api } from '../service/ApiUtils'
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}
const middleWares = [thunkMiddleware.withExtraArgument({ api }), FeeScheduleApiMiddleware];
const middlewareEnhancer = applyMiddleware(...middleWares);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancer = composeEnhancers(middlewareEnhancer);
const store = createStore(rootReducer, enhancer);

export default store;
