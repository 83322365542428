import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import history from '../@history';
import store from './store';
import AppContext from './AppContext';
import routes from './configs/routes.config';
import Layout from './layout/Layout';
import './App.css';
import '@progress/kendo-theme-default/dist/all.css';
function App() {
  return (
    <React.StrictMode>
        <AppContext.Provider value={{ routes }}>
          <Provider store={store}>
            <Router history={history}>
              <Layout history={history}/>
            </Router>
          </Provider>
        </AppContext.Provider>
    </React.StrictMode>
  );
}

export default App;
