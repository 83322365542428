import { faProductHunt } from '@fortawesome/free-brands-svg-icons';
import { faListAlt, faPager, faThLarge, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RouteAccess } from '../AppConstants';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';


const SideNavOptions = [
  { path: '/fee-schedule-grid', title: 'Fee Schedule', icon: () => <FontAwesomeIcon icon={faUserShield as any} />, access: RouteAccess.All },
  { path: '/notifications', title: 'Notifications', icon: () => <NotificationsActiveIcon />, access: RouteAccess.All },

];
export default SideNavOptions;