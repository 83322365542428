import { Button, Container, FormControl, Grid, Input, InputLabel, Paper, Theme, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { withStyles } from '@mui/styles';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import SimpleReactValidator from 'simple-react-validator';
import * as NotificationActions from '../../store/actions/Notification.Actions';

const classNames = require('classnames');

interface IPageProps {
    classes?: any,
    history: any;
    match: any;
    notification: any;
    getNotificationsDetailsById: (notificationId: string) => void;
    resetNotificationDetailsById: () => void;
}


class NotificationForm extends React.Component<RouteComponentProps & IPageProps, any> {
    public validator: SimpleReactValidator = new SimpleReactValidator();

    constructor(props: any) {
        super(props);
        this.state = {

        }
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this, messages: {
                integer: "Please enter only number"
            }
        })
    }

    public componentDidMount() {
        const notificationId = this.props.match.params.id;
        const pathname = this.props.match.path;
        if (pathname === "/view-notification/:id" && notificationId) {
            this.setState({ isEdit: true })
            this.props.getNotificationsDetailsById(notificationId)
        } else {
            this.props.resetNotificationDetailsById();
            this.setState({ isEdit: false })
        }

    }

    public componentDidUpdate(prevProps: IPageProps, prevState: any) {

        if (this.props.notification?.Id !== undefined && (this.props.notification?.Id !== prevState.Id)) {
            const { notification } = prevProps;
            this.setState({ ...notification, isEdit: true });
        }

    }

    componentWillUnmount() {
        this.props.resetNotificationDetailsById();
    }

    private onhandleChangeForm = (event: any) => {
        const value = event.target.value;
        this.setState({
            ...this.state,
            [event.target.name]: value
        });
    }
    private handleSubmitDetails = () => {
        if (this.validator.allValid()) {
            // if (this.state.isEdit) {
            //     this.props.putFeeScheduleDetails(payload)

            // } else {
            //     this.props.postFeeScheduleDetails(payload)
            // }
        } else {
            this.validator.showMessages();
        }
    }

    private handleCancel = () => {
        this.props.history.push('/notifications');
    }


    public render() {
        const isEditScreen = this.props.match.path === "/view-notification/:id";
        this.validator.purgeFields();
        const { classes } = this.props;
        const { id, payload } = this.props.notification;
        return (
            <Paper className={classNames(classes.paper)} elevation={5} square={false}>
                <Typography component="div" className={classes.sectionTitle} style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                    <Typography component="p" align='center' style={{  paddingLeft: 8, margin: '5px' ,fontWeight: 'bolder', fontSize: 18 }}>Notification Details</Typography>
                </Typography>
                <Grid container={true} spacing={4} style={{ marginTop: '25px' }}>
                    <Container maxWidth="lg">
                        {!isEditScreen && <Grid item={true} xs={12} sm={12}>
                            <Grid container={true} spacing={3}>
                                <Grid item={true} xs={12} sm={6}>
                                    <FormControl required={false} fullWidth={true} className={classes.field}>
                                        <InputLabel htmlFor="id">Notification Id</InputLabel>
                                        <Input value={id || ''} disabled={isEditScreen}
                                            onChange={this.onhandleChangeForm} id="id" name="id"
                                            onBlur={() => this.validator.showMessageFor('id')} />
                                    </FormControl>
                                </Grid>
                                <Grid item={true} xs={12} sm={6}>
                                    <FormControl required={true} fullWidth={true} className={classes.field}>
                                        <InputLabel htmlFor="resource">Resource</InputLabel>
                                        <Input value={payload?.resource || ''} disabled={isEditScreen}
                                            onChange={this.onhandleChangeForm}
                                            id="resource" name="resource"
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item={true} xs={12} sm={6}>
                                    <FormControlLabel control={<Checkbox
                                        checked={payload?.auto_capture} disabled={isEditScreen}
                                    />} label="Auto Capture" />
                                </Grid>
                                <Grid item={true} xs={12} sm={6}>
                                    <FormControl required={true} fullWidth={true} className={classes.field}>
                                        <InputLabel htmlFor="amount">Amount</InputLabel>
                                        <Input value={payload?.amount}
                                            onChange={this.onhandleChangeForm} disabled={isEditScreen}
                                            id="amount" name="amount" type="number"
                                            onBlur={() => this.validator.showMessageFor('name')}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item={true} xs={12} sm={6}>
                                    <FormControl required={true} fullWidth={true} className={classes.field}>
                                        <InputLabel htmlFor="fee_amount">Fee Amount</InputLabel>
                                        <Input value={payload?.fee_amount} onChange={this.onhandleChangeForm} type="number"
                                            id="fee_amount" name="fee_amount" disabled={isEditScreen}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item={true} xs={12} sm={6}>
                                    <FormControl required={true} fullWidth={true} className={classes.field}>
                                        <InputLabel htmlFor="status">Status</InputLabel>
                                        <Input value={payload?.status || ''} onChange={this.onhandleChangeForm}
                                            id="status" name="status"
                                            disabled={isEditScreen} />
                                    </FormControl>
                                </Grid>
                                <Grid item={true} xs={12} sm={6}>
                                    <FormControl required={true} fullWidth={true} className={classes.field}>
                                        <InputLabel htmlFor="currency">currency</InputLabel>
                                        <Input value={payload?.currency || ''} onChange={this.onhandleChangeForm}
                                            id="currency" name="currency"
                                            onBlur={() => this.validator.showMessageFor('currency')} disabled={isEditScreen}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item={true} xs={12} sm={6}>
                                    <FormControl required={true} fullWidth={true} className={classes.field}>
                                        <InputLabel htmlFor="pending_reasons">Pending Reasons</InputLabel>
                                        <Input value={payload?.pending_reasons[0]?.reason_message || ''} onChange={this.onhandleChangeForm}
                                            id="pending_reasons" name="pending_reasons"
                                            onBlur={() => this.validator.showMessageFor('pending_reasons')} disabled={isEditScreen}
                                        />

                                    </FormControl>
                                </Grid>

                            </Grid>
                        </Grid>}
                        {isEditScreen && 
                          <Grid item={true} xs={12} sm={12}>
                          <Grid container={true} spacing={3}>
                              <Grid item={true} xs={12} sm={6} md={2}>
                                  <FormControl required={false} fullWidth={true} className={classes.field}>
                                      <InputLabel htmlFor="id">Notification Id :</InputLabel>
                                  </FormControl>
                              </Grid>
                              <Grid item={true} xs={12} sm={6} md={4} marginTop={2}>
                                  <FormControl required={false} fullWidth={true} className={classes.field}>
                                     <label> {id || ''}</label>
                                  </FormControl>
                              </Grid>
                              <Grid item={true} xs={12} sm={6} md={2}>
                                  <FormControl required={false} fullWidth={true} className={classes.field}>
                                      <InputLabel htmlFor="resource">Resource :</InputLabel>
                                  </FormControl>
                              </Grid>
                              <Grid item={true} xs={12} sm={6} md={4} marginTop={2}>
                                  <FormControl required={false} fullWidth={true} className={classes.field}>
                                     <label> {payload?.resource || ''}</label>
                                  </FormControl>
                              </Grid>
                             
                              <Grid item={true} xs={12} sm={6} md={2}>
                                  <FormControl required={false} fullWidth={true} className={classes.field}>
                                      <InputLabel htmlFor="AutoCapture">Auto Capture :</InputLabel>
                                  </FormControl>
                              </Grid>
                              <Grid item={true} xs={12} sm={6} md={4} marginTop={2}>
                                  <FormControl fullWidth={true} className={classes.field}>
                                     <label> {payload?.auto_capture==true?'Yes':'No'}</label>
                                  </FormControl>
                              </Grid>
                              <Grid item={true} xs={12} sm={6} md={2}>
                                  <FormControl fullWidth={true} className={classes.field}>
                                      <InputLabel htmlFor="amount">Amount :</InputLabel>
                                    
                                  </FormControl>
                              </Grid>
                              <Grid item={true} xs={12} sm={6} md={4} marginTop={2}>
                              <label id="amount"  >{payload?.amount}</label>
                              </Grid>

                              <Grid item={true} xs={12} sm={6} md={2}>
                                  <FormControl fullWidth={true} className={classes.field}>
                                      <InputLabel htmlFor="FeeAmount">Fee Amount :</InputLabel>
                                    
                                  </FormControl>
                              </Grid>
                              <Grid item={true} xs={12} sm={6} md={4} marginTop={2}>
                              <label id="FeeAmount"  >{payload?.fee_amount || '-'} </label>
                              </Grid>
                              
                              <Grid item={true} xs={12} sm={6} md={2}>
                                  <FormControl fullWidth={true} className={classes.field}>
                                      <InputLabel htmlFor="Status">Status :</InputLabel>
                                    
                                  </FormControl>
                              </Grid>
                              <Grid item={true} xs={12} sm={6} md={4} marginTop={2}>
                              <label id="Status"  >{payload?.status || ''} </label>
                              </Grid>


                              <Grid item={true} xs={12} sm={6} md={2}>
                                  <FormControl fullWidth={true} className={classes.field}>
                                      <InputLabel htmlFor="currency">Currency :</InputLabel>
                                  </FormControl>
                              </Grid>
                              <Grid item={true} xs={12} sm={6} md={4} marginTop={2}>
                              <label id="currency" >{payload?.currency || ''}  </label>
                              </Grid>

                              <Grid item={true} xs={12} sm={6} md={2}>
                                  <FormControl fullWidth={true} className={classes.field}>
                                      <InputLabel htmlFor="PendingReasons">Pending Reasons :</InputLabel>
                                  </FormControl>
                              </Grid>
                              <Grid item={true} xs={12} sm={6} md={4} marginTop={2}>
                              <label id="PendingReasons" >{payload?.pending_reasons[0]?.reason_message || ''}  </label>
                              </Grid>
                          </Grid>
                      </Grid>}
                        <Grid item={true} xs={12} sm={12} style={{ paddingTop: 20 }}>
                            <Grid container={true} spacing={3} className={classes.alignButton}>
                            {!isEditScreen &&  <Grid item={true} xs={12} sm={6} >
                                   <Button
                                        onClick={this.handleSubmitDetails}
                                        variant="text"
                                        disabled={isEditScreen}
                                        color="primary"
                                        style={{ backgroundColor: '#bbdefb', textAlign: "center", color: '#6d6c6c' }}
                                        className={classes.buttonCommon}
                                    >Save</Button>
                                </Grid>}
                                <Grid item={true} xs={12} sm={6}  >
                                    <Button
                                        onClick={this.handleCancel}
                                        variant="text"
                                        color="secondary"
                                        style={{ backgroundColor: '#f55089', textAlign: "center", color: '#6d6c6c' }}
                                        className={classes.buttonCommon}
                                    >Cancel</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
            </Paper>)
    }
}
const styles = (theme: Theme) => ({
    paper: {
        padding: theme.spacing(3),
        color: theme.palette.text.secondary,
        marginBottom: '6%',
        [theme.breakpoints.only('xs')]: {
            marginBottom: '30%',
        },
        [theme.breakpoints.only('sm')]: {
            marginBottom: '12%',
        },
        [theme.breakpoints.up('md')]: {
            marginBottom: '10%',
        },
    },

    formTitle: {
        fontWeight: 'bold'
    },
    buttonCommon: {
        [theme.breakpoints.between('xs', 'sm')]: {
            width: '100%',
        },
        [theme.breakpoints.between('md', 'xl')]: {
            width: '50%'
        },
    },
    alignButton: {
        marginBottom: '4px',
        [theme.breakpoints.between('md', 'xl')]: {
            flexDirection: 'row-reverse',
            textAlign: 'center',
        },
    },
    sectionTitle: {
        display: 'flex',

        marginBottom: "40px"
    }

});
const mapStateToProps = (state: any) => {

    return {
        notification: state.notification?.notificationDetails
    }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        getNotificationsDetailsById: NotificationActions.getNotificationById,
        resetNotificationDetailsById: NotificationActions.resetNotificationsGridData
    }, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles as any)(NotificationForm) as any) as any);

