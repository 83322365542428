import { Button, Theme } from '@mui/material';
import { withStyles } from '@mui/styles';
import * as React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as FeeScheduleAction from '../../store/actions/FeeSchedule.Actions';
import FeeScheduleViewModel from '../../models/FeeSchedule/FeeScheduleViewModel';
interface IPageProps {
    classes?: any;
    theme?: any;
    history?: any;
    getFeeSchedule: () => void;
    feeScheduleList: FeeScheduleViewModel[]
}

interface IPageState {
    feeScheduleList: FeeScheduleViewModel[];
}

class FeeSchedule extends React.Component<IPageProps, IPageState> {

    constructor(props: IPageProps) {
        super(props);
        this.state = {
            feeScheduleList: this.props.feeScheduleList || []
        }


    }
    componentWillMount() {
        this.props.getFeeSchedule();
    }
 

    public render(): JSX.Element {
        const { classes, feeScheduleList, history } = this.props;
        function getIdField(params: any) {
            return `${params.getValue('accountId') || ''}`;
        }

        const editRecord = (record: any) => {
            history.push(`/edit-fee-schedule/${record.row.serialNumber}`)
        }

        const columns: GridColDef[] = [
            {
                field: 'id',
                headerName: 'id',
                hide: true,
                valueGetter: getIdField
            },
            {
                field: 'serialNumber',
                headerName: 'Caterease Serial Number',
                width: 200,
                editable: false,
            },
            {
                field: 'accountId',
                headerName: 'AccountId',
                width: 330,
                editable: false,
            },
            {
                field: 'name',
                headerName: 'Name',
                width: 250,
                editable: false,
            },
            {
                field: 'cardholder_ic_rate',
                headerName: 'Card Holder Ic Rate %',
                type: 'number',
                width: 150,
                editable: false,
            },
            {
                field: 'cardholder_tran_rate',
                headerName: 'Card Holder Tran Rate $',
                type: 'number',
                width: 150,
                editable: false,
            },
            {
                field: 'merchant_ic_rate',
                headerName: 'Merchant Ic Rate %',
                type: 'number',
                width: 150,
                editable: false,
            },
            {
                field: 'merchant_tran_rate',
                headerName: 'Merchant Tran Rate $',
                type: 'number',
                width: 150,
                editable: false,
            },
            {
                field: 'ach_ic_rate',
                headerName: 'Ach Ic Rate %',
                type: 'number',
                width: 150,
                editable: false,
            },
            {
                field: 'ach_tran_rate',
                headerName: 'Ach Tran Rate $',
                width: 150,
                type: 'number',
                editable: false,
            },
            {
                field: 'merchant_icplus_rate',
                headerName: 'Merchant Ic Plus Rate %',
                type: 'number',
                width: 150,
                editable: false,
            },
            {
                field: 'merchant_icplus_tran_rate',
                headerName: 'Merchant Ic Plus Tran Rate $',
                width: 150,
                type: 'number',
                editable: false,
            },
            {
                field: 'cardholder_icplus_rate',
                headerName: 'Cardholder Ic Plus Rate %',
                type: 'number',
                width: 150,
                editable: false,
            },
            {
                field: 'cardholder_icplus_tran_rate ',
                headerName: 'Cardholder Ic Plus Tran Rate $',
                width: 150,
                type: 'number',
                editable: false,
            },
            {
                field: 'isICP',
                headerName: 'Is ICP',
                width: 100,
                editable: false,
            },
        ];
        return (
            <div className={classes.root}>
                <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                    <Button style={{ width: 130, backgroundColor: 'rgb(187, 222, 251)', marginBottom: 5 }} onClick={() => history.push('/fee-schedule-create')}>Add</Button></div>
                <></>
                <div style={{ height: 580, width: '100%', backgroundColor: '#FFFFFF' }}>
                    <DataGrid
                        rows={feeScheduleList}
                        columns={columns}
                        pageSize={9}
                        rowsPerPageOptions={[9]}
                        onRowDoubleClick={(row) => editRecord(row)}
                        checkboxSelection={false}
                        disableSelectionOnClick={true}
                    />
                </div>
            </div>
        );
    }
}

const styles = (theme: Theme) => ({
    root: {
        flexGrow: 1,
        marginBottom: 0,
        [theme.breakpoints.down('sm')]: {
            marginBottom: '75px',
        },

    },
});

const mapStateToProps = (state: any) => ({
    feeScheduleList: state.feeSchedule?.feeScheduleList
});

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators({
        getFeeSchedule: FeeScheduleAction.fetchFeeScheduleList
    }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles as any)(FeeSchedule) as any));