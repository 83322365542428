import { ActionType } from '../../actions/Helpers';
import FeeScheduleViewModel from '../../../models/FeeSchedule/FeeScheduleViewModel'
import { v4 as uuidv4 } from 'uuid';
const initialState = {
    feeScheduleList: [] as FeeScheduleViewModel,
    feeScheduleListLoading: false as boolean,
    getFeeScheduleByAccountId: {} as FeeScheduleViewModel,
    updateFeeScheduleDetails: false as boolean,
    saveFeeScheduleDetails: false as boolean,
    saveFeeScheduleDetailsLoading: false as boolean,
    updateFeeScheduleDetailsLoading: false as boolean,
    feeScheduleRowPerPage:Number(10)
};
export const feeScheduleReducer = (state = initialState, action = {} as any) => {
    switch (action.type) {
        case ActionType.GET_FEE_SCHEDULE_LIST_REQUEST: {
            return {
                ...state,
                feeScheduleListLoading: true
            };
        }

        case ActionType.GET_FEE_SCHEDULE_LIST_SUCCESS: {
            const feeScheduleList = action.payload
            feeScheduleList.forEach((element: FeeScheduleViewModel) => {
                element.id = uuidv4()
            });
            return {
                ...state,
                feeScheduleList: feeScheduleList,
                feeScheduleListLoading: false
            };
        }
        
        case ActionType.SET_FEE_SCHEDULE_LIST_ROW_PER_PAGE: {
            return {
                ...state,
                feeScheduleRowPerPage: action.payload,
            };
        }
        
        case ActionType.GET_FEE_SCHEDULE_LIST_FAILURE: {
            return {
                ...state,
                feeScheduleList: null,
                feeScheduleListLoading: false
            };
        }
        case ActionType.GET_FEE_SCHEDULE_BY_ID_SUCCESS: {
            return {
                ...state,
                getFeeScheduleByAccountId: action.payload[0]
            };
        }
        case ActionType.GET_FEE_SCHEDULE_BY_ID_FAILURE: {
            return {
                ...state,
                getFeeScheduleByAccountId: {}
            };
        }

        case ActionType.PUT_FEE_SCHEDULE_BY_ID_SUCCESS: {
            return {
                ...state,
                updateFeeScheduleDetails: true,
                updateFeeScheduleDetailsLoading: false,
            };
        }
        case ActionType.PUT_FEE_SCHEDULE_BY_ID_FAILURE: {
            return {
                ...state,
                updateFeeScheduleDetails: false,
                updateFeeScheduleDetailsLoading: false,
            };
        }
        case ActionType.PUT_FEE_SCHEDULE_BY_ID_REQUEST: {
            return {
                ...state,
                updateFeeScheduleDetailsLoading: true
            };
        }

        case ActionType.POST_FEE_SCHEDULE_SAVE_REQUEST: {
            return {
                ...state,
                saveFeeScheduleDetailsLoading: true,
            };
        }

        case ActionType.POST_FEE_SCHEDULE_SAVE_SUCCESS: {
            return {
                ...state,
                saveFeeScheduleDetails: true,
                saveFeeScheduleDetailsLoading: false,
            };
        }
        case ActionType.POST_FEE_SCHEDULE_SAVE_FAILURE: {
            return {
                ...state,
                saveFeeScheduleDetails: false,
                saveFeeScheduleDetailsLoading: false,
            };
        }

        default:
            return state;
    }
};
